import { JobJobTypeEnum } from "src/services/models/Job";

export const jobTypes: { value: JobJobTypeEnum; label: string; productId?: string }[] = [
  {
    value: JobJobTypeEnum.HomeEnergyAssessment,
    label: "Home Energy Assessment",
    productId: "964b31dc-b84a-4b52-882c-19fb8c2c7370",
  },
];

export const jobTypeLookup = new Map<JobJobTypeEnum, string>(
  jobTypes.map(({ value, label }) => [value, label]),
);
