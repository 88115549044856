import { capitalCase } from "change-case";
import type { JobEvent } from "src/services/models/Job";
import { JobJobTypeEnum, JobStatus } from "src/services/models/Job";

export const jobStatusToLabel: Record<JobStatus, string> = {
  [JobStatus.UNALLOCATED]: "Unallocated",
  [JobStatus.BOOKED]: "Booked",
  [JobStatus.UNBOOKED]: "Unbooked",
  [JobStatus.UNASSIGNED]: "Unassigned",
  [JobStatus.COMPLETED]: "Completed",
  [JobStatus.CUSTOMER_PAID]: "Customer Paid",
  [JobStatus.NO_ACCESS]: "No Access",
  [JobStatus.JOBSHEET_APPROVAL_REQUIRED]: "Awaiting Jobsheet Approval",
  [JobStatus.FAILED_TO_AUTO_ALLOCATE]: "Failed to Auto Allocate",
  [JobStatus.PENDING_PAYMENT]: "Pending Payment",
};

export const jobStatusColor: Record<JobStatus, string> = {
  [JobStatus.BOOKED]: "#0B4ED5",
  [JobStatus.UNALLOCATED]: "#9f8b7a",
  [JobStatus.UNBOOKED]: "#DAA402",
  [JobStatus.UNASSIGNED]: "#FF7700",
  [JobStatus.COMPLETED]: "#0DBB7C",
  [JobStatus.CUSTOMER_PAID]: "#0DBB7C",
  [JobStatus.NO_ACCESS]: "#FF7700",
  [JobStatus.FAILED_TO_AUTO_ALLOCATE]: "#E92C2C",
  [JobStatus.JOBSHEET_APPROVAL_REQUIRED]: "#FF7700",
  [JobStatus.PENDING_PAYMENT]: "#DAA402",
};

export const jobTypeColour: Record<JobJobTypeEnum, string> = {
  [JobJobTypeEnum.HomeEnergyAssessment]: "#44aa8a",
};

const jobEventLabel: Record<JobEvent["eventType"], string> = {
  booked: "Job Booked",
  cancelled: "Job Cancelled",
  closed: "Job Closed",
  declined: "Job Declined",
  no_access: "Job No Access",
  rescheduled_by_contact: "Job Rescheduled",
  rescheduled_by_partner: "Job Rescheduled",
  booking_reset_by_partner: "Booking Reset",
  booking_reset_by_tradesperson: "Booking Reset",
  marked_as_incomplete: "Job Marked as Incomplete",
  unallocated_by_partner: "Job Unallocated",
  auto_allocation_restarted: "Auto Allocation Restarted",
  tag_added: "Job Tag Added",
  tag_removed: "Job Tag Removed",
  started_travel_to_job: "Travel to job started",
  started: "Opened Jobsheet",
  completed: "Job Completed",
  allocation_expired: "Allocation Expired",
  costs_pre_approved: "Cost Pre-Approved",
  jobsheet_submitted: "Jobsheet Submitted",
  note_added: "Note Added",
  people_added: "Initials Added",
  people_removed: "Initials Removed",
  tradesperson_allocated: "Tradesperson Allocated",
  comm_sent: "Email Sent",
  payment_comm_sent: "Invoice Sent",
  payment_reminder_sent: "Invoice Reminder Sent",
  jobsheet_approval_required: "Job flagged as requiring jobsheet approval",
  cancelled_by_customer: "Cancelled by customer",
};

export const jobEventToLabel = (eventType: JobEvent["eventType"]) => {
  return jobEventLabel[eventType] ?? capitalCase(eventType);
};

export const humanizeString = (str: string) => {
  return capitalCase(str);
};

export const getShortReferenceId = (jobReferenceId: string) => {
  return jobReferenceId.substring(0, 8).toUpperCase();
};

export const capitalizeAllWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => capitalCase(word))
    .join(" ");
};

export const reformatTransactionRef = (uuid: string) => {
  if (uuid.includes("-")) {
    return uuid.split("-")[0];
  }

  return `${uuid.slice(0, 8)}`;
};
