import type { AxiosInstance } from "axios";
import type { UseMutationOptions } from "react-query";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useMutation } from "react-query";

import { useAxios } from "../context/axios";
import { commsClient, commsClientAdapter } from "./commsClient";
import {
  addJobNote,
  addOrRemovePeopleFromJob,
  addTradespersonNote,
  approveJobsheet,
  blacklistTradespersonPartner,
  createCompany,
  createCompanyPartner,
  createEntityTags,
  createJob,
  createManagedRate,
  createTag,
  createTradespersonCompany,
  deleteAppointment,
  deleteEntityTag,
  deleteInvoice,
  deleteJob,
  deleteManagedRate,
  deleteTag,
  flagInvoice,
  impersonateTradesperson,
  invokeJobsheetSubmit,
  markInvoicesAsPaid,
  markJobAsDone,
  pinJob,
  requireJobsheetApproval,
  restartJobAutoAllocation,
  scheduleJob,
  sendSoftBookingConfirmationComm,
  setJobAutoAllocation,
  setJobForAutoAllocate,
  unallocateJob,
  unflagInvoice,
  updateCompany,
  updateCompanyPartner,
  updateInvoice,
  updateJob,
  updateJobSheet,
  updateManagedRate,
  updateTag,
  updateTradesperson,
  updateTradespersonCompany,
  uploadTradespeopleUpdateCSV,
  uploadInvoiceReportCSV,
  validatePostcode,
  uploadJobsCSV,
} from "./fetchers";

export const useCreateJob = createMutationHook("createJob", createJob);
export const useUpdateJob = createMutationHook("updateJob", updateJob);
export const useUpdateJobSheet = createMutationHook("updateJobSheet", updateJobSheet);
export const useScheduleJob = createMutationHook("scheduleJob", scheduleJob);
export const usePinJob = createMutationHook("pinJob", pinJob);
export const useDeleteJob = createMutationHook("deleteJob", deleteJob);
export const useUnallocateJob = createMutationHook("unallocateJob", unallocateJob);
export const useRestartJobAutoAllocation = createMutationHook(
  "restartJobAutoAllocation",
  restartJobAutoAllocation,
);
export const useSetJobAutoAllocation = createMutationHook(
  "setJobAutoAllocation",
  setJobAutoAllocation,
);
export const useSetJobForAutoAllocate = createMutationHook(
  "setJobForAutoAllocate",
  setJobForAutoAllocate,
);

export const useMarkJobAsDone = createMutationHook("markJobAsDone", markJobAsDone);

export const useAddJobNote = createMutationHook("addJobNote", addJobNote);
export const useInvokeJobsheetSubmit = createMutationHook(
  "invokeJobsheetSubmit",
  invokeJobsheetSubmit,
);
export const useSendSoftBookingConfirmationComm = createMutationHook(
  "sendSoftBookingConfirmationComm",
  sendSoftBookingConfirmationComm,
);
export const useRequireJobsheetApproval = createMutationHook(
  "requireJobsheetApproval",
  requireJobsheetApproval,
);

export const useApproveJobsheet = createMutationHook("requireJobSheetApproval", approveJobsheet);

export const useUpdateInvoice = createMutationHook("updateInvoice", updateInvoice);
export const useDeleteInvoice = createMutationHook("deleteInvoice", deleteInvoice);
export const useMarkInvoicesAsPaid = createMutationHook("markInvoicesAsPaid", markInvoicesAsPaid);
export const useUploadInvoiceReportCSV = createMutationHook(
  "uploadInvoiceReportCSV",
  uploadInvoiceReportCSV,
);
export const useUploadBulkTradespeopleUpdate = createMutationHook(
  "uploadBulkTradespeopleUpdate",
  uploadTradespeopleUpdateCSV,
);

export const useUploadBulkJobs = createMutationHook("uploadBulkJobs", uploadJobsCSV);
export const useValidatePostcode = createMutationHook("validatePostcode", validatePostcode);

export const useUpdateTradesperson = createMutationHook("updateTradesperson", updateTradesperson);
export const useAddTradespersonNote = createMutationHook(
  "addTradespersonNote",
  addTradespersonNote,
);
export const useBlacklistTradesperson = createMutationHook(
  "blacklistTradesperson",
  blacklistTradespersonPartner,
);

export const useImpersonateTradesperson = createMutationHook(
  "impersonateTradesperson",
  impersonateTradesperson,
);

export const useDeleteAppointment = createMutationHook("deleteAppointment", deleteAppointment);

export const useCreateManagedRate = createMutationHook("createManagedRate", createManagedRate);
export const useUpdateManagedRate = createMutationHook("updateManagedRate", updateManagedRate);
export const useDeleteManagedRate = createMutationHook("deleteManagedRate", deleteManagedRate);

export const useFlagInvoice = createMutationHook("flagInvoice", flagInvoice);
export const useUnflagInvoice = createMutationHook("unflagInvoice", unflagInvoice);

export const useAddOrRemovePeopleFromJob = createMutationHook(
  "addOrRemovePeopleFromJob",
  addOrRemovePeopleFromJob,
);

export const useCreateEntityTags = createMutationHook("createEntityTags", createEntityTags);
export const useDeleteEntityTag = createMutationHook("deleteEntityTag", deleteEntityTag);

export const useCreateTag = createMutationHook("createTag", createTag);
export const useUpdateTag = createMutationHook("updateTag", updateTag);
export const useDeleteTag = createMutationHook("deleteTag", deleteTag);

export const useUpdateTemplate = createMutationHook(
  "updateTemplate",
  commsClientAdapter(
    commsClient.templates.updateTemplateApiV1TemplatesIdPatch.bind(commsClient.templates),
  ),
);

export const useCreateTemplate = createMutationHook(
  "createTemplate",
  commsClientAdapter(
    commsClient.templates.createTemplateApiV1TemplatesPost.bind(commsClient.templates),
  ),
);

export const useDeleteTemplate = createMutationHook(
  "deleteTemplate",
  commsClientAdapter(
    commsClient.templates.deleteTemplateApiV1TemplatesIdDelete.bind(commsClient.templates),
  ),
);

export const useRemoveBaseTemplateFromTemplate = createMutationHook(
  "removeBaseTemplateFromTemplate",
  commsClientAdapter(
    commsClient.templates.removeBaseTemplateApiV1TemplatesIdRemoveBaseTemplatePost.bind(
      commsClient.templates,
    ),
  ),
);

export const useMarkTemplateAsLiveForBrand = createMutationHook(
  "markTemplateAsLiveForBrand",
  commsClientAdapter(
    commsClient.templates.liveForBrandApiV1TemplatesIdLiveForBrandPost.bind(commsClient.templates),
  ),
);

export const useMarkTemplateAsLiveForBrandedProduct = createMutationHook(
  "markTemplateAsLiveForBrandedProduct",
  commsClientAdapter(
    commsClient.templates.liveForProductApiV1TemplatesIdLiveForProductsPost.bind(
      commsClient.templates,
    ),
  ),
);

export const useCreateBaseTemplate = createMutationHook(
  "createBaseTemplate",
  commsClientAdapter(
    commsClient.baseTemplates.createBaseTemplateApiV1BaseTemplatesPost.bind(
      commsClient.baseTemplates,
    ),
  ),
);

export const useUpdateBaseTemplate = createMutationHook(
  "updateBaseTemplate",
  commsClientAdapter(
    commsClient.baseTemplates.updateTemplateApiV1BaseTemplatesIdPatch.bind(
      commsClient.baseTemplates,
    ),
  ),
);

export const useDeleteBaseTemplate = createMutationHook(
  "deleteBaseTemplate",
  commsClientAdapter(
    commsClient.baseTemplates.deleteBaseTemplateApiV1BaseTemplatesIdDelete.bind(
      commsClient.baseTemplates,
    ),
  ),
);

export const useCreateBrandTemplateConfig = createMutationHook(
  "createBrandTemplateConfig",
  commsClientAdapter(
    commsClient.brandConfig.createBrandConfigApiV1BrandConfigIdPost.bind(commsClient.brandConfig),
  ),
);

export const useUpdateBrandTemplateConfig = createMutationHook(
  "updateBrandTemplateConfig",
  commsClientAdapter(
    commsClient.brandConfig.updateBrandConfigApiV1BrandConfigIdPatch.bind(commsClient.brandConfig),
  ),
);

export const useCreateCompany = createMutationHook("createCompany", createCompany);
export const useUpdateCompany = createMutationHook("updateCompany", updateCompany);

export const useCreateTradespersonCompany = createMutationHook(
  "createTradespersonCompany",
  createTradespersonCompany,
);

export const useUpdateTradespersonCompany = createMutationHook(
  "updateTradespersonCompany",
  updateTradespersonCompany,
);

export const useUpdateCompanyPartner = createMutationHook(
  "updateCompanyPartner",
  updateCompanyPartner,
);

export const useCreateCompanyPartner = createMutationHook(
  "createCompanyPartner",
  createCompanyPartner,
);

type Mutate<TVariables, TData> = (axios: AxiosInstance, params: TVariables) => Promise<TData>;

type BoundMutationOptions<TVariables, TData> = Omit<
  UseMutationOptions<TData, unknown, TVariables>,
  "mutationKey" | "mutationFn"
>;

function createMutationHook<TVariables, TData>(name: string, mutate: Mutate<TVariables, TData>) {
  function useBoundMutation(options?: BoundMutationOptions<TVariables, TData>) {
    const axios = useAxios();

    const mutation = useMutation({
      mutationKey: name,
      mutationFn: (args: TVariables) => mutate(axios, args),
      ...options,
    });

    return mutation;
  }

  return useBoundMutation;
}
