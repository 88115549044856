import { format, setHours } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";

import { slotMapping } from "./slot-time";
import type { Maybe } from "./types";

/**
 * Get a date/time representing local time in the Europe/London timezone from the UTC date
 */
export function utcToZonedLondon(date: Date): Date {
  return toZonedTime(date, "Europe/London");
}

/**
 * Given a date in the Europe/London timezone, returns a Date with the equivalent UTC time
 */
export function zonedLondonToUtc(date: Date): Date {
  return fromZonedTime(date, "Europe/London");
}

export type PreferredTimeSlot = "morning" | "afternoon" | "allDay";
export type AgreedTimeSlot = "morning" | "afternoon" | "evening";

export function createSlotLondonTz(startDate: Date, slot: AgreedTimeSlot | PreferredTimeSlot) {
  let startDateTime: Date;
  let endDateTime: Date;
  switch (slot) {
    case "morning":
      startDateTime = setHours(startDate, slotMapping["08-12"]);
      endDateTime = setHours(startDate, 4 + slotMapping["08-12"]);
      break;
    case "afternoon":
      startDateTime = setHours(startDate, slotMapping["12-16"]);
      endDateTime = setHours(startDate, 4 + slotMapping["12-16"]);
      break;
    case "evening":
      startDateTime = setHours(startDate, slotMapping["16-20"]);
      endDateTime = setHours(startDateTime, 4 + slotMapping["16-20"]);
      break;
    case "allDay":
      startDateTime = setHours(startDate, slotMapping["08-12"]);
      endDateTime = setHours(startDate, 12 + slotMapping["08-12"]);
  }

  // Not all of our operators work from "Europe/London", so assuming that
  // the date is in the user's local time, we treat the wall clock time as
  // London and convert to Utc
  startDateTime = zonedLondonToUtc(startDateTime);
  endDateTime = zonedLondonToUtc(endDateTime);

  return {
    startDateTime: startDateTime.toJSON(),
    endDateTime: endDateTime.toJSON(),
  };
}

export const formatDateToString = (date: Maybe<Date | string>): string | null => {
  if (!date) return null;
  if (typeof date === "string") return formatDateToString(new Date(date));
  return format(date, "yyyy-MM-dd");
};
