import type { Maybe } from "src/utils/types";

import type { Address } from "./Address";
import type { Contact } from "./Contact";
import type { Flag } from "./Flag";
import type { Metadata } from "./Metadata";
import type { NestedTimeWindow } from "./NestedTimeWindow";
import type { PartnerInvoicing } from "./PartnerInvoicing";

export interface Job {
  id: string;
  appointmentId?: string;
  referenceId: string;
  companyId?: string;
  tradespersonReferenceId: string | null;
  fulfillmentWindow?: NestedTimeWindow | null;
  jobType: JobJobTypeEnum;
  brand: string;
  address: Address;
  contacts: Array<Contact>;
  timezone: string;
  metadata?: Metadata;
  flags?: Flag;
  invoicing: PartnerInvoicing | null;
  createdAt?: string;
  updatedAt?: string;
  ownershipType?: JobOwnershipTypeEnum;
  suppressComms?: boolean;
  modifiedUtc?: string | null;
  useConsolidatedJobsheet?: string;
  status: JobStatus;
  hasInvoice: boolean;
  tradespersonId: string;
  company?: Company;
  closingNotes?: string;
  preferredSlots: PreferredSlot[];
  autoAllocate: boolean;
  cannotBeFulfilled: boolean;
  autoAllocationRound: number | null;
  managedRateId?: string;
  partnerDomainId?: string;
  deletedAt: string;
  pinned: boolean;
  partnerId: string;
  productId: Maybe<string>;
  people: string[];
  tags: string[];
  jobsheetApprovalRequired: boolean;
}

export interface Company {
  id: string;
  name: string;
}

export interface PreferredSlot {
  startDatetime: string;
  endDatetime: string;
  slotName: string;
}

export enum JobJobTypeEnum {
  HomeEnergyAssessment = "home_energy_assessment",
}

export enum JobOwnershipTypeEnum {
  Unknown = "unknown",
  ManagedHousing = "managed_housing",
  Landlord = "landlord",
  Homeowner = "homeowner",
}

export enum JobStatus {
  UNALLOCATED = "unallocated",
  UNASSIGNED = "unassigned",
  UNBOOKED = "unbooked",
  BOOKED = "booked",
  COMPLETED = "submitted",
  CUSTOMER_PAID = "customer_paid",
  NO_ACCESS = "no_access",
  FAILED_TO_AUTO_ALLOCATE = "failed_to_auto_allocate",
  JOBSHEET_APPROVAL_REQUIRED = "jobsheet_approval_required",
  PENDING_PAYMENT = "pending_payment",
}

interface JobEventBase {
  id: string;
  createdAt: string;
  actor?: null | "partner" | "tradesperson" | "contact" | "system";
  actorInstanceUuid?: string;
  actorUserEmailAddress: string | null;
  context: unknown;
}

export type JobEvent = JobEventBase &
  (
    | { eventType: "booked" }
    | { eventType: "started_travel_to_job" }
    | { eventType: "started" }
    | { eventType: "jobsheet_submitted" }
    | { eventType: "completed" }
    | { eventType: "closed" }
    | { eventType: "declined" }
    | { eventType: "no_access" }
    | { eventType: "allocation_expired" }
    | {
        eventType: "tradesperson_allocated";
        context: {
          tradespersonId?: string | null;
          allocatedFromTradespersonId?: string | null;
        };
      }
    | { eventType: "booking_reset_by_partner" }
    | { eventType: "booking_reset_by_tradesperson" }
    | { eventType: "rescheduled_by_contact" }
    | { eventType: "rescheduled_by_partner" }
    | { eventType: "costs_pre_approved" }
    | { eventType: "marked_as_incomplete" }
    | { eventType: "unallocated_by_partner" }
    | { eventType: "auto_allocation_restarted" }
    | { eventType: "note_added"; context: { note?: string | null; shouldAddNoteToTp?: boolean } }
    | { eventType: "comm_sent"; context: { note?: string | null } }
    | { eventType: "cancelled"; context: { residentCommAction?: string | null } }
    | { eventType: "people_added"; context: { initials?: string } }
    | { eventType: "people_removed"; context: { initials?: string } }
    | { eventType: "auto_allocation_restarted" }
    | { eventType: "tag_added"; context: { tagLabel: string } }
    | { eventType: "tag_removed"; context: { tagLabel: string } }
    | { eventType: "payment_comm_sent" }
    | { eventType: "payment_reminder_sent" }
    | { eventType: "jobsheet_approval_required" }
    | {
        eventType: "cancelled_by_customer";
        context: { customerCancellationReason?: string | null };
      }
  );
